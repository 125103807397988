import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { cubicInOut } from '../../utils/easings';

import { duration, stagger } from '../../utils/transitions';

const clipDirection = {
  left: `0 0, 0 0, 0 100%, 0% 100%`,
  right: `100% 0, 100% 0, 100% 100%, 100% 100%`,
  down: `0 0, 100% 0, 100% 0, 0 0`,
};

const Outer = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  transition: ${(props) => `clip-path ${duration}s ${cubicInOut}
    ${((props.order - 1) * (props.active ? stagger + props.delay : 0)).toFixed(
      1
    )}s`};
  clip-path: ${(props) => `polygon(
    ${
      props.active
        ? `0 0, 100% 0, 100% 100%, 0% 100%`
        : clipDirection[props.direction]
    }
  )`};
  img,
  div {
    will-change: transform;
    transition: ${(props) => `transform ${duration}s ${cubicInOut}
      ${(
        (props.order - 1) *
        (props.active ? stagger + props.delay : 0)
      ).toFixed(1)}s !important`};
    transform: ${(props) => `scale(${props.active ? 1 : 1.1})`};
  }
`;

export default function Unclip({
  className,
  active,
  order = 1,
  delay = 0,
  direction = 'left',
  children,
}) {
  return (
    <Outer className={className}>
      <Inner active={active} order={order} delay={delay} direction={direction}>
        {children}
      </Inner>
    </Outer>
  );
}

Unclip.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool.isRequired,
  order: PropTypes.number,
  delay: PropTypes.number,
  direction: PropTypes.string,
  children: PropTypes.node.isRequired,
};
